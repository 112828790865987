import React from 'react';

import { graphql, Link  } from 'gatsby';
import { Grid, Typography, Button, Container, Box } from '@mui/material';
// import LocalizedLink from '../components/LocalizedLink/LocalizedLink';
import IndexLayout from '../layouts';

import { BlogEntry, BlogHero } from '../components/Blog'

import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { LocaleTypes } from '../data/locales';

interface BlogPageProps {
  data: {
    allContentfulBlogPost: {
      edges: {
        node: {
          title: string;
          slug: string;
          body: {
            childMarkdownRemark: {
              excerpt: string;
            }
          }
          date: string;
          author?: AuthorProps;
        };
      }[];
    };
  };
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

interface BlogEntryProps {
  slug: string;
  image: string;
  title: string;
  excerpt: string;
  date: string;
  className: string;
  author?: AuthorProps;
}

interface AuthorProps {
  name: string;
  bio: string;
  profilePic: {
    resize: {
      src: string;
    };
  };
}

const BlogFeaturedEntry: React.FC<BlogEntryProps> = ({
  slug,
  image,
  title,
  excerpt,
  author,
  date
}) => (
  <Grid container
    md={12} 
    py={'4rem'}
    display='flex'
    flexDirection='column'
  >
    <Grid sx={{ display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center' }}>
      <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:{ md: '1000px', xs:'300px'}, height:{md: '480px'}, overflow:'hidden' }}>
        <Link to={`/blog/${slug}`}>
          <img src={image} alt="" style={{ width:'100%', height:'100%' }}/>
        </Link>
      </Box>
      <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', my:'2rem', px:{lg: '16rem', sm:'none'} }}>
        <Link to={`/blog/${slug}`}>
          <Typography variant='h2' color="text.dark" fontSize={'25px'}>
            {title}
          </Typography>
          <Typography variant='body2' color="text.grey" fontSize={'20px'}>
            {excerpt}
          </Typography>
        </Link>
      </Box>
    </Grid>
  </Grid>
);

const BlogPage: React.FC<BlogPageProps> = ({data, pathContext, location}) => {
  console.log(data)
  const posts = data.allContentfulBlogPost.edges.slice(1);
  const firstPost = data.allContentfulBlogPost.edges[0].node;

  const content = (
      <>
        <SEO
          title="Blog de Ingenio Kushki"
        />
        <BlogHero />
        <div className="container py-4">
          <div className="row">
            <Grid 
              container xs={12} sm={12} md={12} lg={12}
              display='flex'
              direction='row'
              alignItems='center'
              justifyContent='center'
              px='4rem'
            >
              <BlogFeaturedEntry
                className="mt-4"
                slug={firstPost.slug}
                image={firstPost.mainImage.url}
                title={firstPost.title}
                excerpt={firstPost.body.childMarkdownRemark.excerpt}
                date={firstPost.date}
                author={firstPost.author}
              />
              <Grid item display='flex' flexDirection='row' flexWrap='wrap' sx={{ py:'2rem' }} md={8}>
                {posts.map(({ node }: any) => {
                  return (
                    <Grid key={node.title} lg={4} sx={{width:'30%', overflow:'hidden', my:'2rem'}}>
                      <BlogEntry
                        slug={node.slug}
                        image={node.mainImage.url}
                        title={node.title}
                        excerpt={node.body.childMarkdownRemark.excerpt}
                        date={node.date}
                      /> 
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="ingenio-bg-footer">
          <Footer />
        </div>
      </>
    )

  return (
    <IndexLayout location={location} content={content} />
  );
}

export default BlogPage;

export const query = graphql`
  query {
    allContentfulBlogPost(
      filter: {vertical: {eq: true}}
    ) {
      nodes {
        vertical
        slug
        node_locale
      }
      edges {
        node {
          mainImage {
            url
          }
          slug
          title
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          vertical
        }
      }
    }
  }
`
